import React, { memo, useContext } from 'react'
import { Route, Routes } from "react-router-dom";
import { AuthenticationContext } from '../context/AuthenticationContext'
import { Box } from '@mui/material';
import { SuAuthenticationContext } from '../context/super_user_context/SuAuthenticationContext';
import SuperUserRoute from './stacks/SuperUserRoute';
import SuDashboard from '../pages/admin/super_user/SuDashboard';
import PlantMIS from '../pages/admin/super_user/PlantMIS';
import Analytics from '../pages/admin/super_user/Analytics';
// import Analytics2 from '../pages/admin/super_user/Analytics2';
import Analytics3 from '../pages/admin/super_user/Analytics3';
import GisLocation from '../pages/admin/super_user/GisLocation';
import KwhGraph from '../pages/admin/super_user/KwhGraph';
import PlantList from '../pages/admin/super_user/PlantList';
import PlantImageList from '../pages/admin/super_user/PlantImageList';
import EnergyGeneration from '../pages/admin/super_user/analytics2/EnergyGeneration';
import CUF from '../pages/admin/super_user/analytics2/CUF';
import SpecificPower from '../pages/admin/super_user/analytics2/SpecificPower';
import SpecificYield from '../pages/admin/super_user/analytics2/SpecificYield';
import PeakGeneration from '../pages/admin/super_user/analytics2/PeakGeneration';
// import PeakGeneration from '../pages/admin/super_user/analytics2/PeakGeneration';
const PrivateRoute = React.lazy(() => import('./stacks/PrivateRoute'))
const Dashboard = React.lazy(() => import('../pages/admin/page/dashboard/Dashboard'))
const Analysis = React.lazy(() => import('../pages/admin/page/analysis/Analysis'))
const PvMonitoring = React.lazy(() => import('../pages/admin/page/pv_monitoring/PvMonitoring'))
const WMS = React.lazy(() => import('../pages/admin/page/wms/WMS'))
const PublicRoute = React.lazy(() => import('./stacks/PublicRoute'))
const Login = React.lazy(() => import('../pages/auth/Login'))
const EnergyReporting = React.lazy(() => import('../pages/admin/page/energy_reporting/EnergyReporting'))
const Inverter = React.lazy(() => import('../pages/admin/page/inverter/Inverter'))
const Inverter1 = React.lazy(() => import('../pages/admin/page/inverter1/Inverter1'))
const Grid = React.lazy(() => import('../pages/admin/page/grid/Grid'))
const DG = React.lazy(() => import('../pages/admin/page/dg/DG'))
const EnergyMeter = React.lazy(() => import('../pages/admin/page/energy_meter/EnergyMeter'))
const SMB = React.lazy(() => import('../pages/admin/page/smb/SMB'))
const MainRouter = () => {
    const { IsLogged, UserType } = useContext(AuthenticationContext);
    return (
        <Routes>
            {
                IsLogged && UserType == "User" ?
                    (
                        <Route path="/" element={<PrivateRoute />}>
                            {/* <Route path='analysis' element={<Analysis />} /> */}
                            <Route index element={<Inverter />} />
                            {/* <Route path='inverter1' element={<Inverter1 />} /> */}
                            {/* <Route path='grid' element={<Grid />} /> */}
                            {/* <Route path='dg' element={<DG />} /> */}
                            {/* <Route path='energy-meter' element={<EnergyMeter />} /> */}
                            {/* <Route path='smb' element={<SMB />} /> */}
                            {/* <Route path='pv_monitoring' element={<PvMonitoring />} /> */}
                            <Route path='wms' element={<WMS />} />
                            {/* <Route path='energy-reporting' element={<EnergyReporting />} /> */}
                            <Route path="*" element={<Box>Page Not Found</Box>} />
                        </Route>
                    )

                    : IsLogged && UserType == "SUSER" ?

                        (
                            <Route path="/" element={<SuperUserRoute />}>
                                <Route index element={<SuDashboard />} />
                                <Route path='analytics' element={<Analytics />} />
                                <Route path='energy-generation' element={<EnergyGeneration />} />
                                <Route path='cuf' element={<CUF />} />
                                <Route path='specific-power' element={<SpecificPower />} />
                                <Route path='specific-yield' element={<SpecificYield />} />
                                <Route path='peak-generation' element={<PeakGeneration />} />
                                <Route path='analytics3' element={<Analytics3 />} />
                                <Route path='gis-location' element={<GisLocation />} />
                                <Route path='kwh-graph' element={<KwhGraph />} />
                                <Route path='plant-list' element={<PlantList />} />
                                <Route path='plant-image-list' element={<PlantImageList />} />
                                <Route path='plant-mis' element={<PlantMIS />} />
                                <Route path="*" element={<Box>Page Not Found</Box>} />
                            </Route>

                        ) :

                        <Route path="login" element={<PublicRoute />}>
                            <Route index element={<Login />} />
                            {/* <Route path="services" element={<Services />} />
                       <Route path="about" element={<About />} /> */}
                            <Route path="*" element={<Box>Page Not Found</Box>} />
                        </Route>
            }
        </Routes>


    )
}


export default memo(MainRouter)
