import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import PersonIcon from '@mui/icons-material/Person';
import LoginIcon from '@mui/icons-material/Login';
import ListItemButton from '@mui/material/ListItemButton';
import { Link, useLocation } from 'react-router-dom';
import PowerIcon from '@mui/icons-material/Power';
import PieChartIcon from '@mui/icons-material/PieChart';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Button, Card, CardActions, Paper, Stack, useMediaQuery, useTheme } from '@mui/material';
import { GasMeter, GraphicEq, Grid3x3Outlined, NotificationsOutlined, Palette, PieChartSharp, SmartButton, SolarPower } from '@mui/icons-material'
import MonitorIcon from '@mui/icons-material/Monitor';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import SpaIcon from '@mui/icons-material/Spa';
import AllOutIcon from '@mui/icons-material/AllOut';
import ReportIcon from '@mui/icons-material/Report';
import AnchorIcon from '@mui/icons-material/Anchor';
import DetailsIcon from '@mui/icons-material/Details';
import { AuthenticationContext } from '../../context/AuthenticationContext';
import { useState } from 'react';
import { useEffect } from 'react';
import Image from 'mui-image';
import ColorPaletteDialog from '../palette/ColorPaletteDialog';
import { LightTheme } from '../../Themes/MyTheme';
// import { useThemeContext } from '../../context/ThemeContext';
const drawerWidth = 240;
function Header({ window, children }) {
    const { palette, spacing, breakpoints, shape: { borderRadius } } = useTheme()
    const [colorPaletteDialogOpen, setColorPaletteDialogOpen] = useState(false);
    // const { updateThemeVariables } = useThemeContext();

    const handleColorPaletteDialogOpen = () => {
        setColorPaletteDialogOpen(true);
    };

    const handleColorPaletteSelect = (colors) => {

        // updateThemeVariables(updatedThemeVariables);

    };

    const handleColorPaletteDialogClose = () => {
        setColorPaletteDialogOpen(false);
    };
    // const { window } = props;
    const [currentTime, setCurrentTime] = useState(new Date());
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        // Clear the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array to run the effect only once on mount

    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();
    const dates = currentTime.getDate()

    const year = currentTime.getFullYear();
    const month = currentTime.getMonth() + 1; // Months are 0-indexed
    const day = currentTime.getDate();

    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;


    const [open, setOpen] = useState(true);
    const { logOut } = React.useContext(AuthenticationContext)
    const [selectedIndex, setSelectedIndex] = useState(0);
    const location = useLocation()
    const laptopSize = useMediaQuery(breakpoints.down('xl'))
    const isLarge = useMediaQuery(breakpoints.up('xl'))
    const matchUpMd = useMediaQuery(breakpoints.up('lg'))

    // Close the drawer when the screen size is tablet
    useEffect(() => {
        if (laptopSize) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    }, [laptopSize])

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const drawer = (
        <Box
            color={'background.default'}
            sx={{
                height: '100%',
                borderRight: 1,
                borderRightColor: 'lightgrey',
                bgcolor: 'primary.main',
            }}>
            <Stack sx={{ pt: spacing(1) }} alignItems={'center'} rowGap={spacing(1)}>
                <Card>
                    <CardActions>
                        <Image fit='contain' alt="Remy Sharp" src={require('../../assets/img/logos/logo 1.svg').default} />
                    </CardActions>
                </Card>
                <Typography variant='body1'>eSolar Plant Monitoring System</Typography>
            </Stack>
            <List
                sx={{
                    // selected and (selected + hover) states
                    '&& .Mui-selected, && .Mui-selected:hover': {
                        bgcolor: 'primary.light',
                        '&, & .MuiListItemIcon-root': {
                            color: 'background.default',
                        },
                    },
                    // hover states 
                    '& .MuiListItemButton-root:hover': {
                        bgcolor: 'primary.light',
                        '&, & .MuiListItemIcon-root': {
                            color: 'primary.main',
                        },
                        '&, & .ListItemText-root': {
                            color: 'background.default',
                        },
                    },

                }}

                component="nav" aria-label="main mailbox folders">
                {/* <ListItemButton
                    onClick={() => isLarge ? null : setOpen(false)}
                    selected={location.pathname === '/'}
                    LinkComponent={Link}
                    to='.'
                >
                    <ListItemIcon>
                        <PieChartIcon sx={{
                            fontSize: '1.3rem'
                        }} />
                    </ListItemIcon>
                    <ListItemText sx={{}} primary="Dashboard" />
                </ListItemButton> */}

                <ListItemButton
                    onClick={() => isLarge ? null : setOpen(false)}
                    selected={location.pathname === '/'}
                    LinkComponent={Link}
                    to='.'
                >
                    <ListItemIcon color='inherit'>
                        <SolarPower sx={{ fontSize: '1.3rem' }} />
                    </ListItemIcon>
                    <ListItemText primary="Inverter" />
                </ListItemButton>

                {/* <ListItemButton
                    onClick={() => isLarge ? null : setOpen(false)}
                    selected={location.pathname === '/inverter1'}
                    LinkComponent={Link}
                    to={'inverter1'}
                >
                    <ListItemIcon color='inherit'>
                        <SolarPower sx={{ fontSize: '1.3rem' }} />
                    </ListItemIcon>
                    <ListItemText primary="Inverter 1" />
                </ListItemButton>
                <ListItemButton
                    onClick={() => isLarge ? null : setOpen(false)}
                    selected={location.pathname === '/grid'}
                    LinkComponent={Link}
                    to={'grid'}
                >
                    <ListItemIcon color='inherit'>
                        <Grid3x3Outlined sx={{ fontSize: '1.3rem' }} />
                    </ListItemIcon>
                    <ListItemText primary="Grid" />
                </ListItemButton>
                <ListItemButton
                    onClick={() => isLarge ? null : setOpen(false)}
                    selected={location.pathname === '/dg'}
                    LinkComponent={Link}
                    to={'dg'}
                >
                    <ListItemIcon color='inherit'>
                        <GraphicEq sx={{ fontSize: '1.3rem' }} />
                    </ListItemIcon>
                    <ListItemText primary="DG" />
                </ListItemButton>
                <ListItemButton
                    onClick={() => isLarge ? null : setOpen(false)}
                    selected={location.pathname === '/energy-meter'}
                    LinkComponent={Link}
                    to={'energy-meter'}
                >
                    <ListItemIcon color='inherit'>
                        <GasMeter sx={{ fontSize: '1.3rem' }} />
                    </ListItemIcon>
                    <ListItemText primary="Energy Meter" />
                </ListItemButton>
                <ListItemButton
                    onClick={() => isLarge ? null : setOpen(false)}
                    selected={location.pathname === '/smb'}
                    LinkComponent={Link}
                    to={'smb'}
                >
                    <ListItemIcon color='inherit'>
                        <SmartButton sx={{ fontSize: '1.3rem' }} />
                    </ListItemIcon>
                    <ListItemText primary="SMB" />
                </ListItemButton>
                <ListItemButton
                    onClick={() => isLarge ? null : setOpen(false)}
                    selected={location.pathname === '/analysis'}
                    LinkComponent={Link}
                    to={'analysis'}
                >
                    <ListItemIcon color='inherit'>
                        <AnalyticsIcon sx={{ fontSize: '1.3rem' }} />
                    </ListItemIcon>
                    <ListItemText primary="Analysis" />
                </ListItemButton>
                <ListItemButton
                    onClick={() => isLarge ? null : setOpen(false)}
                    selected={location.pathname === '/pv_monitoring'}
                    LinkComponent={Link}
                    to={'pv_monitoring'}
                >
                    <ListItemIcon>
                        <MonitorIcon sx={{ fontSize: '1.3rem' }} />
                    </ListItemIcon>
                    <ListItemText primary="PV Monitoring" />
                </ListItemButton> */}

                <ListItemButton
                    onClick={() => isLarge ? null : setOpen(false)}
                    selected={location.pathname === '/wms'}
                    LinkComponent={Link}
                    to={'wms'}
                >
                    <ListItemIcon>
                        <SpaIcon sx={{ fontSize: '1.3rem' }} />
                    </ListItemIcon>
                    <ListItemText primary="WMS" />
                </ListItemButton>
                {/* 
                <ListItemButton
                    onClick={() => isLarge ? null : setOpen(false)}
                    selected={location.pathname === '/energy-reporting'}
                    LinkComponent={Link}
                    to={'energy-reporting'}
                >
                    <ListItemIcon>
                        <ReportIcon sx={{ fontSize: '1.3rem' }} />
                    </ListItemIcon>
                    <ListItemText primary="Energy Reporting" />
                </ListItemButton>
                <ListItemButton
                    onClick={() => isLarge ? null : setOpen(false)}
                    selected={location.pathname === '/event-log-reports'}
                    LinkComponent={Link}
                    to={'event-log-reports'}
                >
                    <ListItemIcon>
                        <AnchorIcon sx={{ fontSize: '1.3rem' }} />
                    </ListItemIcon>
                    <ListItemText primary="Event Log Reports" />
                </ListItemButton>
                <ListItemButton
                    onClick={() => isLarge ? null : setOpen(false)}
                    selected={location.pathname === '/plant-total-power-curve'}
                    LinkComponent={Link}
                    to={'plant-total-power-curve'}
                >
                    <ListItemIcon>
                        <PowerIcon sx={{ fontSize: '1.3rem' }} />
                    </ListItemIcon>
                    <ListItemText primary="Plant Total Power Curve" />
                </ListItemButton>
                <ListItemButton
                    onClick={() => isLarge ? null : setOpen(false)}
                    selected={location.pathname === '/outage'}
                    LinkComponent={Link}
                    to={'outage'}
                >
                    <ListItemIcon>
                        <AllOutIcon sx={{ fontSize: '1.3rem' }} />
                    </ListItemIcon>
                    <ListItemText primary="Outage" />
                </ListItemButton>
                <ListItemButton
                    onClick={() => isLarge ? null : setOpen(false)}
                    selected={location.pathname === '/user-details'}
                    LinkComponent={Link}
                    to={'user-details'}
                >
                    <ListItemIcon>
                        <PersonIcon sx={{ fontSize: '1.3rem' }} />
                    </ListItemIcon>
                    <ListItemText primary="User Details" />
                </ListItemButton> */}

                <ListItemButton
                    onClick={() => logOut()}
                >
                    <ListItemIcon>
                        <LoginIcon sx={{ fontSize: '1.3rem' }} />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItemButton>


            </List>
        </Box>
    );

    // Remove this const when copying and pasting into your project.
    // const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                color='primary'
                position="fixed"
                variant='elevation'
                elevation={2}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
                <Toolbar sx={{}}>
                    <IconButton
                        aria-label="open drawer"
                        onClick={open ? handleDrawerClose : handleDrawerOpen}
                        edge="start"
                        color='inherit'
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography flexGrow={1} component="div" sx={{ pl: spacing(1), display: "flex", columnGap: spacing(1), alignItems: "center" }}>
                        <PieChartSharp sx={{ fontSize: '1.3rem' }} />
                        <Typography variant="h5"> Dashboard</Typography>
                    </Typography>
                    <Box direction={'row'} alignItems={'center'} justifyContent={'flex-end'}>

                        <Box flex={1} columnGap={spacing(1)} display={'flex'} alignItems={'center'}>

                            {/* Timing */}

                            <Box flex={1} sx={{ display: { sm: 'none', xs: 'none', md: 'none', lg: 'flex', xl: 'flex' } }} columnGap={spacing(1)} alignItems={'center'}>
                                <Typography variant="body2" noWrap> {dates} / {month} / {year} </Typography>
                                <Stack p={spacing(1)} component={Card} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography noWrap variant='body2' textAlign={'center'}>
                                        {formattedHours}:{minutes < 10 ? `0${minutes}` : minutes}:
                                        {seconds < 10 ? `0${seconds}` : seconds}
                                    </Typography>
                                    <Typography variant='body2'> {ampm}</Typography>
                                </Stack>
                            </Box>

                            {/* Timing */}
                            <IconButton size='small' color='inherit'>
                                <NotificationsOutlined />
                            </IconButton>
                            <Paper>
                                <Image width={spacing(8)} wrapperStyle={{ padding: spacing(1) }} fit='contain' alt="Logics Power" src={require('../../assets/img/logos/logo 1.svg').default} />
                            </Paper>
                            <IconButton color='inherit' onClick={handleColorPaletteDialogOpen}>
                                <Palette />
                            </IconButton>
                            {/* <Button variant='outlined' color='inherit' onClick={handleColorPaletteDialogOpen}>
                                Choose Color Palette
                            </Button> */}
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                variant={matchUpMd ? 'persistent' : 'temporary'}
                open={open}
                onClose={handleDrawerClose}
                ModalProps={{ keepMounted: true }}
                sx={{
                    width: open ? drawerWidth : 0,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth },
                }}
            >
                <Toolbar />

                {drawer}

            </Drawer>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: spacing(1), width: `calc(100% - ${drawerWidth}px)` }}>
                <Toolbar />

                {children}

            </Box>
            <ColorPaletteDialog
                open={colorPaletteDialogOpen}
                onClose={handleColorPaletteDialogClose}
                onSelectColors={handleColorPaletteSelect}
            />
        </Box>
    );
}

export default Header;
