import React, { createContext, useEffect, useState } from 'react'
import AxiosApi from '../api/AxiosApi';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export const AuthenticationContext = createContext()
export function AuthenticationProvider({ children }) {
    const Navigate = useNavigate()
    const [IsLogged, setIsLogged] = useState(false)
    const [UserType, setUserType] = useState(null)
    const [LoginLoading, setLoginLoading] = useState(false)
    const [LoggedInUserInfo, setLoggedInUserInfo] = useState(null)
    // LOGIN_NAME is LOGIN_ID
    function userLogin(LOGIN_ID, PASSWORD, WEB_KEY, USER_TYPE) {
        localStorage.setItem('LoginID', LOGIN_ID)
        localStorage.setItem('LoginPassword', PASSWORD)

        console.log("CHECKING WEB KEY", WEB_KEY)

        setLoginLoading(true)
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        let bodyContent = `LOGIN_NAME=${LOGIN_ID}&PW=${PASSWORD}&APP_KEY=${WEB_KEY}`;
        AxiosApi.post('login/LoginWEB', bodyContent, headersList)
            .then((res) => {
                localStorage.setItem('UserType', USER_TYPE)
                localStorage.setItem('LoggedInUserInfo', JSON.stringify(res?.data))
                localStorage.setItem('LCODE', res?.data?.Loc_Code)
                setLoggedInUserInfo(res?.data)
                setLoginLoading(false)
                setUserType(USER_TYPE)
                setIsLogged(true)

            }).catch((er) => {
                localStorage.removeItem('UserType')
                alert('Invalid Credentials!')
                console.log('err', er)
                setLoginLoading(false)
            });

    }
    // LOGIN_ID is LOGIN_ID
    function superUserLogin(LOGIN_ID, PASSWORD, USER_TYPE) {
        localStorage.setItem('LoginID', LOGIN_ID)
        setLoginLoading(true)
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        let bodyContent = `LOGIN_ID=${LOGIN_ID}&PASSWORD=${PASSWORD}&USER_TYPE=${USER_TYPE}`;
        AxiosApi.post('CMN_05/SLogin', bodyContent, headersList)
            .then((res) => {
                localStorage.setItem('UserType', USER_TYPE)
                localStorage.setItem('LoggedInUserInfo', JSON.stringify(res?.data))
                localStorage.setItem('LCODE', res?.data?.Loc_Code)
                setLoggedInUserInfo(res?.data)
                setUserType(USER_TYPE)
                setIsLogged(true)
                setLoginLoading(false)
            }).catch((er) => {
                localStorage.removeItem('UserType')
                alert('Invalid Credentials!')
                console.log('err', er)
                setLoginLoading(false)
            });

    }
    function logOut() {
        localStorage.clear()
        setLoggedInUserInfo(null)
        setIsLogged(false)
        setUserType(null)
        // navigate('./')
    }

    useEffect(() => {
        const data = localStorage.getItem('LoggedInUserInfo')
        const UserType = localStorage.getItem('UserType')
        const userData = JSON.parse(data)
        if (userData && UserType) {
            setUserType(UserType)
            setIsLogged(true)
            setLoggedInUserInfo(userData)
            Navigate('/')
        } else {
            setUserType(null)
            setIsLogged(false)
            setLoggedInUserInfo(null)
            localStorage.clear()
            Navigate('login')
        }
    }, [IsLogged])


    return (
        <AuthenticationContext.Provider value={{ UserType, superUserLogin, setIsLogged, IsLogged, setLoggedInUserInfo, LoggedInUserInfo, LoginLoading, userLogin, logOut }}>
            {children}
        </AuthenticationContext.Provider>
    )
}
